<template>
  <div>
    <div v-if="EnableStatus" class="Widget-box">
      <div class="Widget-setting-header">
        <div>
          <h3>嵌入</h3>
          <h5>
            复制以下脚本并将其粘贴到结束标记之前的网站HTML源代码中，此代码必须插入到要显示该窗件的每个页面中。
          </h5>
        </div>
        <div class="Enable-button" v-show="false">
          <span v-if="EnableStatus" class="TurnedOn">已开启</span>
          <span v-if="!EnableStatus" class="NotOpen">未开启</span>
          <el-switch
            v-model="EnableStatus"
            active-color="#006DCC"
            inactive-color="#f0f0f0"
            @change="changeStatus"
            disabled
          >
          </el-switch>
        </div>
      </div>
      <div class="Widget-setting-footer">
        <div class="script-box" @click="copyText(code)">
          <span :class="copied ? 'js-bg' : ''">{{ code }}</span>
        </div>
      </div>
      <div class="Widget-setting-header">
        <div>
          <h3>高级设置</h3>
          <h5>实时聊天由环信提供技术支持，您可以直接在环信中配置高级设置。</h5>
        </div>
      </div>
      <div class="Widget-setting-footer">
        <el-button @click="getJWT">高级设置</el-button>
      </div>
    </div>
    <div class="not-open" v-if="!EnableStatus">
      <img :src="defaultImg" alt="" />
      <p>
        <span style="margin-right: 10px"
          >在线聊天账号未开通，请先开启聊天功能</span
        >
        <el-button type="primary" @click="goToSeat">去开启</el-button>
      </p>
    </div>
    <el-dialog
      title=""
      custom-class="huanxin"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <div>
        <iframe
          :src="URL"
          frameborder="0"
          style="width: 100%; height: 600px"
        ></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { copyText } from "@/utils/copyInfo";
import { getJWT } from "./api.js";
import * as chatsettingsApi from "./api.js";

export default {
  data() {
    return {
      dialogVisible: false,
      URL: "",
      defaultImg: require("@/views/systemSettings/components/chatSetting/Img/study.png"),
      copied: false,
      //是否开启小组件
      EnableStatus: false,
      //嵌入小组件代码
      code: "<script src='//cloudcc.kefu.easemob.com/webim/easemob.js?configId=285289a8-a649-429e-9f1f-16e26c49458e'><\\/script>",
    };
  },
  methods: {
    copyText,
    async getJWT() {
      let result = await getJWT();
      if (result.result) {
        this.jwt = result.data;

        //iframe嵌入环信工作台
        this.URL =
          "http://cloudcc.kefu.easemob.com/v1/access/jwt?jwt=" +
          this.jwt +
          "&returnTo=http://cloudcc.kefu.easemob.com/mo/agent/webapp/chat";
        this.dialogVisible = true;
        // window.location(URL, "_blank")
        //       document.querySelector('.chat-dialog').innerHTML = document.querySelector('.chat-dialog').innerHTML + `<iframe
        //   src="http://cloudcc.kefu.easemob.com/v1/access/jwt?jwt=${this.jwt}&returnTo=http://cloudcc.kefu.easemob.com/mo/agent/webapp/chat"
        //   frameborder="0"
        //   style="width:100%;height:100%"
        // ></iframe>`
      }
    },
    //获取jwt密钥
    //   async getJWT () {
    //     let result = await getJWT({
    //       email: "zhanghr@cloudcc.com",
    //       name: "Admin",
    //       externalId: "",
    //       state: "Online"
    //     });
    //     if (result.result) {
    //     //   window.open(`http://cloudcc.kefu.easemob.com/v1/access/jwt?jwt=${result.data}&returnTo=http://cloudcc.kefu.easemob.com/mo/agent/webapp/chat`)
    //       //iframe嵌入环信工作台
    // //       document.querySelector('.chat-dialog').innerHTML = document.querySelector('.chat-dialog').innerHTML + `<iframe
    // //   src="http://cloudcc.kefu.easemob.com/v1/access/jwt?jwt=${result.data}&returnTo=http://cloudcc.kefu.easemob.com/mo/agent/webapp/chat"
    // //   frameborder="0"
    // //   style="width:100%;height:100%"
    // // ></iframe>`
    //     }
    //   },
    async getStatus() {
      // 判断该组织状态
      let res = await chatsettingsApi.getTrialQualification();
      if (res.result) {
        if (res.data.config.status === 0 || res.data.config.status === 1) {
          this.EnableStatus = true;
        } else if (
          res.data.config.status === 2 ||
          res.data.config.status === 3
        ) {
          this.EnableStatus = false;
        }
      }
    },
    changeStatus() {},
    goToSeat() {
      // this.getJWT()
      this.$router.push({
        path: "/systemSettings/setuppage/chat/servicesSeat",
      });
    },
  },
  mounted() {
    this.getStatus();
  },
};
</script>
<style lang="scss" scoped>
h3,
h5 {
  margin: 0;
}
.Widget-box {
  padding: 20px;
}
.Widget-setting-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  h3 {
    margin-bottom: 10px;
  }
  .Enable-button {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      margin-right: 10px;
    }
    .TurnedOn {
      color: #006dcc;
    }
    .NotOpen {
      color: #f0f0f0;
    }
  }
}
.Widget-setting-footer {
  padding-bottom: 20px;
  .script-box {
    width: 100%;
    min-height: 110px;
    background: #f8f8f8;
    border-radius: 3px;
    border: 1px solid #dedcda;
    padding: 20px;
    cursor: pointer;
  }
  .el-button {
    background: #006dcc;
    color: #ffffff;
  }
}
.js-bg {
  background-color: #c0c0c0;
}
.not-open {
  padding-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    text-align: center;
  }
}
.huanxin {
  min-height: 500px;
}
</style>